* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
  width: 80%;
  margin-inline: auto;
  max-width: 1400px;
}

.wrapper-block {
  width: 80%;
  margin-inline: auto;
  max-width: 1400px;
}

.badge {
  background: #ddd;
  padding: 0.5em 1em 0.5em 2em;
  border-radius: 0px 8px 8px 0px;
  position: relative;
}
.large-buttonn {
  width: 100%;
  padding: 4px;
  max-height: 2.6rem;
  height: 2.4rem;

  border: none;
}

.link-decoration {
  text-decoration: none;
  text-align: 'left';
  color: '#3498db';
}
.top-view-list {
  padding: 4px;
  display: flex;
  flexdirection: row;
  alignitems: center;
  width: 100%;
  gap: 16px;
  marginbottom: 8px;
}

.data-block {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 8px;
  column-gap: 8px;
  row-gap: 5px;
}

.data-placeholder {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border: 1px solid black;
  color: #77838f;
  background: rgba(119, 131, 143, 0.1);
  border-color: transparent;
}
.circular-placeholder {
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
.data-trx {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 4px;
  align-items: center;

  column-gap: 8px;
}
.text-secondary {
  color: #77838f !important;
}
.small,
small {
  font-size: 90%;
  font-weight: 600;
}

h4,
h5,
h6 {
  font-weight: 400;
}

select:focus {
  border: 2px solid #333;
}

option:hover,
option:focus {
  background-color: #333;
  color: #fff;
}

option:selected {
  background-color: #333;
  color: #fff;
}

select {
  width: 200px;
  font-size: 16px;
}

option {
  background-color: #eee;
  color: #000;
}

.header-main {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  border-bottom: 1px solid;
}
.header-items {
  display: flex;
  flex-wrap: nowrap;
  gap: 2rem;
  align-items: center;
  padding: 8px;
}
.header-text {
  font-size: 18px;
}
.textWhiteSpace {
  white-space: 'nowrap';
}
